import PhoneMask from '@dns-modules/base-ui-input-row/masks/phone-mask';

/**
 * Использование базового поля ввода номера телефона.
 * (со скрытием маски при потере фокуса)
 */
export const usePhoneInput = () => {
	/** Телефон */
	const phone = ref('');

	/** Валидно ли значение */
	const phoneIsValid = ref(true);

	/** Ключ, по которому обновляется поле ввода телефона при включении/отключении маски */
	const updatingKey = ref(0);

	/** Маска номера телефона. */
	const phoneMask = new PhoneMask();

	/** Очистка значения */
	const clearPhone = () => {
		phone.value = '';
	};
	/** Обработчик события 'blur' */
	const blurPhoneInput = () => {
		// При снятии фокуса с пустого поля ввода обновляем компонент, чтобы скрыть маску.
		// То же самое, если в поле ввода остался только международный код
		if (!phone.value || phone.value.length === 1) {
			updatingKey.value += 1;
		}
	};

	/**
	 * Установка значения поля "Телефон".
	 *
	 * @param {string} value Значение из поля
	 */
	const setPhone = (value: string) => {
		phone.value = value;
	};

	/** Валидация телефона */
	const checkPhoneIsValid = () => {
		phoneIsValid.value = 11 === phone.value.length;
	};

	return {
		phone,
		phoneIsValid,
		updatingKey,
		clearPhone,
		blurPhoneInput,
		setPhone,
		checkPhoneIsValid,
	}
}
